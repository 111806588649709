<template>
  <div class="py-4">
    <v-sheet
      class="pa-6 d-flex"
      color="white"
      elevation="4"
      :min-height="260"
      rounded="lg"
      outlined
      shaped
    >
      <v-row no-gutters align-content="space-between">
        <v-col
          v-if="
            productsWithCurrentUUIDSorting && productsWithCurrentUUIDSorting[0]
          "
          cols="12"
          class="py-1"
        >
          <v-fade-transition mode="in-out">
            <div v-if="!loading">
              <v-row
                v-for="(product, index) in productsWithCurrentUUIDSorting"
                :key="index"
                class="pb-2 product-text"
                no-gutters
                justify="space-between"
              >
                <!-- Product | NOT_PAID -->
                <template
                  v-if="
                    product.status ===
                      $store.getters['order/paymentStatus'][0] ||
                    product.uuid === currentUUID
                  "
                >
                  <v-col>
                    <v-row class="no-gutters align-start justify-start">
                      <v-col cols="2" class="pa-0">
                        <v-checkbox
                          @change="toggleProduct($event, product)"
                          class="pa-0 ma-0"
                          color="primary"
                          :input-value="
                            product.status ===
                              $store.getters['order/paymentStatus'][1] &&
                            product.uuid === currentUUID
                              ? true
                              : false
                          "
                          hide-details
                        />
                      </v-col>
                      <v-col class="text-start product-description">
                        {{ `${product.name} x1` }}
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="4" class="pa-0">
                    <div class="text-end">
                      {{
                        `${currencySymbol()}${formatPrice(
                          Number(product.unitPrice),
                          2
                        )}`
                      }}
                    </div>
                  </v-col>
                </template>

                <!-- Product | IN_PROGRESS -->
                <template
                  v-else-if="
                    product.status === $store.getters['order/paymentStatus'][1]
                  "
                >
                  <v-col>
                    <v-row class="no-gutters align-start justify-start">
                      <v-col cols="2" class="pa-0">
                        <v-icon color="success"
                          >mdi-clock-time-five-outline</v-icon
                        >
                      </v-col>
                      <v-col class="text-start product-description">
                        {{ `${product.name} x1` }}
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="4">
                    <div class="text-end">
                      {{
                        `${currencySymbol()}${formatPrice(
                          Number(product.unitPrice),
                          2
                        )}`
                      }}
                    </div>
                  </v-col>
                </template>

                <!-- Product | PAID -->
                <template
                  v-else-if="
                    product.status === $store.getters['order/paymentStatus'][2]
                  "
                >
                  <v-col class="grey--text">
                    <v-row class="no-gutters align-start justify-start">
                      <v-col cols="2" class="pa-0">
                        <v-icon color="grey">mdi-check-bold</v-icon>
                      </v-col>
                      <v-col
                        class="text-start product-description text-decoration-line-through"
                      >
                        {{ `${product.name} x1` }}
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="4">
                    <div
                      class="text-end grey--text text-decoration-line-through"
                    >
                      {{
                        `${currencySymbol()}${formatPrice(
                          Number(product.unitPrice),
                          2
                        )}`
                      }}
                    </div>
                  </v-col>
                </template>
              </v-row>
            </div>
          </v-fade-transition>

          <v-row
            v-if="currentTip.amount > 0"
            class="pb-2 pt-5 product-text"
            no-gutters
            justify="space-between"
          >
            <!-- Tip | NOT_PAID -->
            <template
              v-if="
                currentTip.status ===
                  $store.getters['order/paymentStatus'][0] ||
                currentTip.uuid === currentUUID
              "
            >
              <v-col>
                <v-row class="no-gutters align-start justify-start">
                  <v-col cols="2" class="pa-0">
                    <v-checkbox
                      @change="toggleTip"
                      class="pa-0 ma-0"
                      color="primary"
                      hide-details
                      :input-value="
                        currentTip.status ===
                          $store.getters['order/paymentStatus'][1] &&
                        currentTip.uuid === currentUUID
                          ? true
                          : false
                      "
                    />
                  </v-col>
                  <v-col class="text-start product-description">
                    Propina
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="4" class="pa-0">
                <div class="text-end">
                  {{
                    `${currencySymbol()}${formatPrice(currentTip.amount, 2)}`
                  }}
                </div>
              </v-col>
            </template>

            <!-- Tip | IN_PROGRESS -->
            <template
              v-else-if="
                currentTip.status === $store.getters['order/paymentStatus'][1]
              "
            >
              <v-col>
                <v-row class="no-gutters align-start justify-start">
                  <v-col cols="2" class="pa-0">
                    <v-icon color="success">mdi-clock-time-five-outline</v-icon>
                  </v-col>
                  <v-col class="text-start product-description">
                    Propina
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="4">
                <div class="text-end">
                  {{
                    `${currencySymbol()}${formatPrice(currentTip.amount, 2)}`
                  }}
                </div>
              </v-col>
            </template>

            <!-- Tip | PAID -->
            <template
              v-else-if="
                currentTip.status === $store.getters['order/paymentStatus'][2]
              "
            >
              <v-col class="grey--text">
                <v-row class="no-gutters align-start justify-start">
                  <v-col cols="2" class="pa-0">
                    <v-icon color="grey">mdi-check-bold</v-icon>
                  </v-col>
                  <v-col
                    class="text-start product-description text-decoration-line-through"
                  >
                    Propina
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="4">
                <div class="text-end grey--text text-decoration-line-through">
                  {{
                    `${currencySymbol()}${formatPrice(currentTip.amount, 2)}`
                  }}
                </div>
              </v-col>
            </template>
          </v-row>
        </v-col>

        <no-products-found v-else />

        <v-col cols="12" class="pt-1">
          <v-divider></v-divider>
          <v-row
            class="pt-3 product-text font-weight-medium"
            no-gutters
            justify="space-between"
          >
            <v-col cols="8">
              <div class="text-start">TOTAL</div>
            </v-col>
            <v-col cols="4">
              <div class="text-end">
                {{ currencySymbol()
                }}{{ formatPrice($store.getters["order/totalPrice"], 2) }}
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-sheet>

    <FooterMenuOrderDetailsPaymentPartial
      :uuid="currentUUID"
      :hasAnyProductSelected="currentUUIDInProgressProductsTotalAmount > 0"
      :isTipLeftAlone="isTipLeftAlone"
      :paymentAmount="
        currentTip.status === $store.getters['order/paymentStatus'][1] &&
        currentTip.uuid === currentUUID
          ? currentUUIDInProgressProductsTotalAmount + currentTip.amount
          : currentUUIDInProgressProductsTotalAmount
      "
    />
  </div>
</template>

<script>
import { getUUID } from "@/utils/uuid.js";
import { formatPrice, currencySymbol } from "@/utils/price.js";
import NoProductsFound from "@/components/NoProductsFound";
import FooterMenuOrderDetailsPaymentPartial from "@/layouts/FooterMenuOrderDetailsPaymentPartial.vue";

export default {
  components: {
    NoProductsFound,
    FooterMenuOrderDetailsPaymentPartial,
  },

  data() {
    return {
      loading: false,
      currentUUID: "",
    };
  },

  props: {
    products: {
      type: Array,
      default: () => [],
    },
    paidProducts: {
      type: Array,
      default: () => [],
    },
    inProgressProducts: {
      type: Array,
      default: () => [],
    },
    notPaidProducts: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    formatPrice,
    currencySymbol,
    initUUID() {
      const multipaymentUuid = window.localStorage.getItem("multipaymentUuid");

      if (!multipaymentUuid || multipaymentUuid === "null") {
        this.currentUUID = getUUID();
      } else {
        this.currentUUID = multipaymentUuid;
      }
    },
    toggleTip(checkboxBoolean) {
      if (checkboxBoolean) {
        this.$store.dispatch("order/setTipUUID", this.currentUUID);
      } else {
        this.$store.dispatch("order/setTipUUID", null);
      }
    },
    toggleProduct(checkboxBoolean, product) {
      this.$store.dispatch("order/setProductUUID", {
        checkboxBoolean,
        product,
        uuid: this.currentUUID,
      });

      // Bug Fix Hack | Resets checkboxes "input-value" state
      // to be correctly synced with firebase state.
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 250);
    },
  },

  computed: {
    currentTip() {
      return this.$store.state.order.currentTip;
    },

    paymentStatus() {
      return this.$store.getters["order/paymentStatus"];
    },

    isTipLeftAlone() {
      if (!this.currentUUID) return false;
      const currentTip = this.$store.getters["order/currentTip"];
      if (currentTip.status != "NOT_PAID") return false;
      if (currentTip.amount === 0) return false;
      return this.isThereAnyProductToChoose;
    },

    isThereAnyProductToChoose() {
      return !this.$store.getters["order/sortedExtendedProducts"].filter(
        (x) => x.uuid === undefined
      )[0];
    },

    currentUUIDInProgressProducts() {
      return this.inProgressProducts.filter((element) => {
        return element.uuid === this.currentUUID;
      });
    },

    currentUUIDInProgressProductsTotalAmount() {
      let total = 0;
      this.currentUUIDInProgressProducts.forEach((element) => {
        total += Number(element.unitPrice);
      });
      return total;
    },

    productsWithCurrentUUIDSorting() {
      const inProgressProductsSorted = [];

      // Filled checkboxes stays down
      this.inProgressProducts.forEach((element) => {
        if (element.uuid === this.currentUUID) {
          inProgressProductsSorted.push(element);
        } else {
          inProgressProductsSorted.unshift(element);
        }
      });

      return [
        ...this.paidProducts,
        ...inProgressProductsSorted,
        ...this.notPaidProducts,
      ];
    },
  },

  mounted() {
    this.initUUID();
  },
};
</script>

<style lang="sass" scoped>
.product-text
  font-size: 0.92rem !important
  overflow-wrap: break-word
  font-weight: 400
  letter-spacing: 0.02rem !important
</style>
