<template>
  <v-container class="px-4" id="menu-order-details-payment-partial">
    <v-slide-x-transition
      v-if="$store.state.order.products && $store.state.order.products[0]"
      hide-on-leave
    >
      <div>
        <h5 class="text-subtitle-1 font-weight-medium grey--text pt-4">
          Restarán pagar {{ currencySymbol()
          }}{{
            formatPrice($store.getters["order/remainingPaymentLiveAmount"], 2)
          }}
        </h5>
        <partial-payment-details
          :products="$store.getters['order/sortedExtendedProducts']"
          :paid-products="$store.getters['order/paidSortedExtendedProducts']"
          :in-progress-products="
            $store.getters['order/inProgressSortedExtendedProducts']
          "
          :not-paid-products="
            $store.getters['order/notPaidSortedExtendedProducts']
          "
        />
      </div>
    </v-slide-x-transition>

    <base-section v-else space="0">
      <v-row v-if="loadingContent" justify="center" class="py-12">
        <base-progress-circular />
      </v-row>

      <no-products-found v-else />
    </base-section>
  </v-container>
</template>

<script>
import PartialPaymentDetails from "@/components/PartialPaymentDetails";
import { formatPrice, currencySymbol } from "@/utils/price.js";
import NoProductsFound from "@/components/NoProductsFound";

export default {
  name: "MenuOrderDetailsPaymentPartial",

  provide: {
    heading: { align: "start" },
  },

  components: {
    PartialPaymentDetails,
    NoProductsFound,
  },

  data: () => ({
    loadingContent: false,
  }),

  mounted: function () {
    this.fakeLoader();
  },

  methods: {
    // Since products binding is event orientated and very quick,
    // a vuex loader variable isn't propagated effectively.
    fakeLoader() {
      this.loadingContent = true;
      setTimeout(() => {
        this.loadingContent = false;
      }, 1000);
    },

    formatPrice,
    currencySymbol,
  },
};
</script>
