<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" class="text-center">
        <v-icon size="120" class="mx-auto mb-6" color="primary lighten-2">
          mdi-cart-off
        </v-icon>
        <base-subheading class="text-center text--disabled" :title="text" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "No tienes productos agregados!",
    },
  },
  provide: {
    heading: { align: "center" },
  },
};
</script>

<style>

</style>