var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-4"},[_c('v-sheet',{staticClass:"pa-6 d-flex",attrs:{"color":"white","elevation":"4","min-height":260,"rounded":"lg","outlined":"","shaped":""}},[_c('v-row',{attrs:{"no-gutters":"","align-content":"space-between"}},[(
          _vm.productsWithCurrentUUIDSorting && _vm.productsWithCurrentUUIDSorting[0]
        )?_c('v-col',{staticClass:"py-1",attrs:{"cols":"12"}},[_c('v-fade-transition',{attrs:{"mode":"in-out"}},[(!_vm.loading)?_c('div',_vm._l((_vm.productsWithCurrentUUIDSorting),function(product,index){return _c('v-row',{key:index,staticClass:"pb-2 product-text",attrs:{"no-gutters":"","justify":"space-between"}},[(
                  product.status ===
                    _vm.$store.getters['order/paymentStatus'][0] ||
                  product.uuid === _vm.currentUUID
                )?[_c('v-col',[_c('v-row',{staticClass:"no-gutters align-start justify-start"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"2"}},[_c('v-checkbox',{staticClass:"pa-0 ma-0",attrs:{"color":"primary","input-value":product.status ===
                            _vm.$store.getters['order/paymentStatus'][1] &&
                          product.uuid === _vm.currentUUID
                            ? true
                            : false,"hide-details":""},on:{"change":function($event){return _vm.toggleProduct($event, product)}}})],1),_c('v-col',{staticClass:"text-start product-description"},[_vm._v(" "+_vm._s(`${product.name} x1`)+" ")])],1)],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"4"}},[_c('div',{staticClass:"text-end"},[_vm._v(" "+_vm._s(`${_vm.currencySymbol()}${_vm.formatPrice( Number(product.unitPrice), 2 )}`)+" ")])])]:(
                  product.status === _vm.$store.getters['order/paymentStatus'][1]
                )?[_c('v-col',[_c('v-row',{staticClass:"no-gutters align-start justify-start"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"2"}},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-clock-time-five-outline")])],1),_c('v-col',{staticClass:"text-start product-description"},[_vm._v(" "+_vm._s(`${product.name} x1`)+" ")])],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"text-end"},[_vm._v(" "+_vm._s(`${_vm.currencySymbol()}${_vm.formatPrice( Number(product.unitPrice), 2 )}`)+" ")])])]:(
                  product.status === _vm.$store.getters['order/paymentStatus'][2]
                )?[_c('v-col',{staticClass:"grey--text"},[_c('v-row',{staticClass:"no-gutters align-start justify-start"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"2"}},[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-check-bold")])],1),_c('v-col',{staticClass:"text-start product-description text-decoration-line-through"},[_vm._v(" "+_vm._s(`${product.name} x1`)+" ")])],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"text-end grey--text text-decoration-line-through"},[_vm._v(" "+_vm._s(`${_vm.currencySymbol()}${_vm.formatPrice( Number(product.unitPrice), 2 )}`)+" ")])])]:_vm._e()],2)}),1):_vm._e()]),(_vm.currentTip.amount > 0)?_c('v-row',{staticClass:"pb-2 pt-5 product-text",attrs:{"no-gutters":"","justify":"space-between"}},[(
              _vm.currentTip.status ===
                _vm.$store.getters['order/paymentStatus'][0] ||
              _vm.currentTip.uuid === _vm.currentUUID
            )?[_c('v-col',[_c('v-row',{staticClass:"no-gutters align-start justify-start"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"2"}},[_c('v-checkbox',{staticClass:"pa-0 ma-0",attrs:{"color":"primary","hide-details":"","input-value":_vm.currentTip.status ===
                        _vm.$store.getters['order/paymentStatus'][1] &&
                      _vm.currentTip.uuid === _vm.currentUUID
                        ? true
                        : false},on:{"change":_vm.toggleTip}})],1),_c('v-col',{staticClass:"text-start product-description"},[_vm._v(" Propina ")])],1)],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"4"}},[_c('div',{staticClass:"text-end"},[_vm._v(" "+_vm._s(`${_vm.currencySymbol()}${_vm.formatPrice(_vm.currentTip.amount, 2)}`)+" ")])])]:(
              _vm.currentTip.status === _vm.$store.getters['order/paymentStatus'][1]
            )?[_c('v-col',[_c('v-row',{staticClass:"no-gutters align-start justify-start"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"2"}},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-clock-time-five-outline")])],1),_c('v-col',{staticClass:"text-start product-description"},[_vm._v(" Propina ")])],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"text-end"},[_vm._v(" "+_vm._s(`${_vm.currencySymbol()}${_vm.formatPrice(_vm.currentTip.amount, 2)}`)+" ")])])]:(
              _vm.currentTip.status === _vm.$store.getters['order/paymentStatus'][2]
            )?[_c('v-col',{staticClass:"grey--text"},[_c('v-row',{staticClass:"no-gutters align-start justify-start"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"2"}},[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-check-bold")])],1),_c('v-col',{staticClass:"text-start product-description text-decoration-line-through"},[_vm._v(" Propina ")])],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"text-end grey--text text-decoration-line-through"},[_vm._v(" "+_vm._s(`${_vm.currencySymbol()}${_vm.formatPrice(_vm.currentTip.amount, 2)}`)+" ")])])]:_vm._e()],2):_vm._e()],1):_c('no-products-found'),_c('v-col',{staticClass:"pt-1",attrs:{"cols":"12"}},[_c('v-divider'),_c('v-row',{staticClass:"pt-3 product-text font-weight-medium",attrs:{"no-gutters":"","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"text-start"},[_vm._v("TOTAL")])]),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"text-end"},[_vm._v(" "+_vm._s(_vm.currencySymbol())+_vm._s(_vm.formatPrice(_vm.$store.getters["order/totalPrice"], 2))+" ")])])],1)],1)],1)],1),_c('FooterMenuOrderDetailsPaymentPartial',{attrs:{"uuid":_vm.currentUUID,"hasAnyProductSelected":_vm.currentUUIDInProgressProductsTotalAmount > 0,"isTipLeftAlone":_vm.isTipLeftAlone,"paymentAmount":_vm.currentTip.status === _vm.$store.getters['order/paymentStatus'][1] &&
      _vm.currentTip.uuid === _vm.currentUUID
        ? _vm.currentUUIDInProgressProductsTotalAmount + _vm.currentTip.amount
        : _vm.currentUUIDInProgressProductsTotalAmount}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }